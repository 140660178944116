import { createContext, useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { io } from 'socket.io-client';
import useTrait from "../utils/useTraint";
import { AccountContext } from "./account_context";






const AviatorContext = createContext()
export default AviatorContext;



export function AviatorContextProvider({ children }) {

    const { updateAmount, account, accountName } = useContext(AccountContext)

    const [socketStatus, setSocketStatus] = useState('pending')
    const socketRef = useRef()


    const [currentGame, setCurrentGame] = useState({})
    const currentGameRef = useRef()



    //history
    const [historyPointsList, setHistoryPointsList] = useState([])

    const [bets, setBets] = useState([])
    const [prevBets, setPrevBets] = useState([])
    const [showPrevBets, setShowPrevBets] = useState(false)

    const [myBetsHistory, setMyBetsHistory] = useState([])


    const [bet, setBet] = useState()
    const betRef = useRef()

    const [autoCashout, setAutoCashout] = useState()
    const autoCashoutRef = useRef()
    const [actionLoading, setActionLoading] = useState(false)



    const createBet = (amount) => { return { "amount": amount, gameId: currentGame['_id'], _id: account['_id'], status: 'lobby', 'avatar': account['avatar'], accountAlias: accountName() } }
    const updateCashoutBet = (incrementor, wonAmount, cashoutOut) => { return { ...betRef.current, wonAmount: wonAmount, incrementor: incrementor, cashoutAt: cashoutOut } }

    const updateJoinBet = (betId,timestamp) => { return { ...betRef.current,_id :betId, joinAt: timestamp, status: 'pending' } }

    const isGameRunning = () => currentGameRef.current && currentGameRef.current.status == 'running'
    const isGameCrashed = () => currentGameRef.current && currentGameRef.current.status == 'crashed'
    const isGameInLobby = () => currentGameRef.current && currentGameRef.current.status == 'lobby'
    const currentBet = () => betRef.current

    const isBetPending = () => betRef.current && betRef.current.status == 'lobby'
    const isBetJoined = () => betRef.current && betRef.current.status == 'pending'
    const isBetCashout = () => betRef.current && betRef.current.status == 'cashout'


    const placeBet = (amount) => {
        const _bet = createBet(amount)
        setBet(_bet)
        joinGameEmit(_bet)
    }

    const cancelBet = (type) => setBet(undefined)



    const cashOutBet = () => {
        if (!isGameRunning()) return;

        setActionLoading(true)

        socketRef.current.emit('cashout', currentBet(), async (response) => {
            setActionLoading(false)
            if (response['success']) {
                const data = response['data']
                const mulX = data['incrementor']
                const wonAmount = data['cashoutAmount']


                toast.success('You have crashed out!' + mulX + " " + wonAmount)
             

                setBet(undefined)

                // const updatedBet = updateCashoutBet(mulX, wonAmount)
                // setBet(updatedBet)



                setMyBetsHistory(prevState => {
                    const newState = [...prevState]
                    const foundItem = newState.find((item) => item['betId'] == data['betId'])
                    if(!foundItem) return prevState;
                    
                    foundItem['status'] = 'cashout'
                    foundItem['incrementor'] = mulX;
                    foundItem['cashoutAmount'] = wonAmount;
                    setMyBetsHistory(newState)
                })



            } else {
                const message = response['message']
                toast.error(message)
            }

        })


    }




    const onGameStateChange = async function (game) {

        
        setCurrentGame(game)
    }





    const incrementor = (_data) => {
    
        const gameState = currentGameRef.current;
        const newIncState = { ...gameState, 'incremented': _data['incremented'] };
        setCurrentGame(newIncState)
        const incValue = _data['incremented']

        if (incValue >= autoCashoutRef.current) {
            cashOutBet()
        }
    }




    const playerJoined = (data) => {
        setBets(prevState => {
            prevState.push(data)
            setBets(prevState)
        })
    }


    const cashoutBets = (bet) => {
        const mul = bet['incrementor']
        const wonAmount = bet['cashout_amount']



        setBets((prevBets) => {
            const newState = [...prevBets]
            const foundItem = newState.find(item => item['_id'] == bet['betId'] && item['gameId'] == bet['gameId'])

            foundItem['status'] = 'cashout'
            foundItem['cashout_x'] = mul;
            foundItem['cashout_amount'] = wonAmount;

            return newState

        })
    }




    function connectSocket() {
        console.log(process.env.REACT_APP_SERVER_SOCKET + "crash", 'server socket url')
        let socket = io(process.env.REACT_APP_SERVER_SOCKET + "crash", {
            transports: ['websocket'],
            query: {
                authorization: localStorage.getItem('token')
            }
        });
        socketRef.current = socket;
        socket.on("connect", () => {
            setSocketStatus('connected')
            console.log(socket.id, 'socket connected'); // x8WIv7-mJelg7on_ALbx
        });

        socket.on("disconnect", () => {
            setSocketStatus('disconnected')

        });

        socket.on("error", (e) => {
            setSocketStatus('error')
        });


        socket.on("connect_error", (err) => {

            console.log('connect_error', err)


            if (err.message == 'xhr poll error') {
                setSocketStatus('no_internet_error')
            }

            else if (err.message == 'unauthorized_user' || err.message == 'unknown_user') {
                setSocketStatus('unauthorized_user')
            }
            else {
                setSocketStatus('connect_error')
            }
        });


        socket.on('game_status', onGameStateChange)

        socket.on('incrementor', incrementor)


        socket.on('new_player_joined', playerJoined)

        socket.on('player_cashout', cashoutBets)

        socket.on('init', init)

    }


    function init(data) {
        const betHistory = data['bet_history'];
        const gamePayload = data['game']
        setCurrentGame(gamePayload)
        setHistoryPointsList(betHistory)
    }


    function joinGameEmit(mBet) {
        
        if (!isGameInLobby()) return;

        
        setActionLoading(true)
        socketRef.current.emit('join_game', mBet, (data) => {
            if (data['success']) {
                toast.success('Bet placed!')
                const responseData = data['data']
                const betId = responseData['_id']
                const updatedBet = updateJoinBet(betId,responseData['join_timestamp'])
                setBet(updatedBet)


                setMyBetsHistory((prevState) => {
                    const newState = [...prevState]
                    newState.push(updatedBet)
                    return newState;
                })


                //updateAmount(-amount)
            } else {
                setBet(undefined)
                console.error('joinGameEmit : ERR  ')
                toast.error(data.message)
            }
            setActionLoading(false)
        })
    }




    const makeAutoCashout = (cashoutX) => {
        setAutoCashout(cashoutX)
    }


    useEffect(() => {


        if(!currentGame || !currentGame['status']) return;
        currentGameRef.current = currentGame;

        console.log('useEffect game->',currentGameRef.current)

        
        if(currentGame.status=='lobby'){
            if (isBetPending()){
                joinGameEmit(betRef.current)
            }
        }


        if(currentGame.status=='crashed'){
            setHistoryPointsList(prevState => {
                return [...prevState, currentGame.incremented]
            })
            if(!isBetPending()) setBet(undefined)
        }

    }, [currentGame['status']])


    useEffect(() => {
        betRef.current = bet;
    }, [bet])


    useEffect(() => {
        autoCashoutRef.current = autoCashout;
    }, [autoCashout])




    useEffect(() => {
        connectSocket()
    }, [account])



    const cleanGame = () => {
        setCurrentGame({})
        setBet(undefined)
        
    }

    const closeSocket = (params) => {
        if(socketRef.current) socketRef.current.disconnect()
        socketRef.current =undefined
    }

    return <AviatorContext.Provider value={{
        placeBet,
        actionLoading,
        currentGame,
        cashOutBet,
        cancelBet,
        bets,
        bet,
        prevBets,
        showPrevBets,
        setShowPrevBets,
        myBetsHistory,
        makeAutoCashout,
        historyPointsList,

        connectSocket,
        closeSocket,
        cleanGame,
        socketStatus,

        isGameInLobby,
        isGameCrashed,
        isGameRunning,

        isBetJoined,
        isBetPending,
        isBetCashout
    }}>
        {children}
    </AviatorContext.Provider>
}